.outer {
    margin: 16px;
}

.chatwindow {
    border: 1px solid gray;
    box-shadow: 2px 2px 0px 0px;
    width: 600px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    display: flex;
}

.footer input {
    flex: 1;
}

.readystateindicator {
    color: gray;
    font-size: 12px;
}

.active {
    color: green;
}