.file-select {
  position: relative;
  width: 400px;
  height: 300px;
  border-radius: 8px;
  margin-bottom: 16px;
  transition: all 0.3s ease;
}

.file-select.drag-over {
  background-color: rgb(255 236 206 / 11%);
  transition: background-color 0.3s ease;
}

.drop-zone {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-zone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.drop-zone-button, .drop-zone-button h2 {
  color: rgba(255,255,255,0.9);
  line-height: 1.2;
}
.drop-zone-button {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 24px !important;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    box-sizing: border-box;
  }
.drop-zone-button h2 {margin: 8px 0 0 0;}
.drop-zone-button { text-transform: uppercase;}

.file-names {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.file-name {
  display: flex;
  align-items: center;
  padding: 4px 8px 6px;
  border-radius: 5px;
  line-height: 1
}
