.rbc-time-content > * + * > *,
.rbc-day-slot .rbc-time-slot,
.rbc-timeslot-group, .rbc-time-content,
.rbc-time-header-content, .rbc-header, .rbc-header + .rbc-header,
.rbc-day-bg + .rbc-day-bg, .rbc-time-view, .rbc-month-view,
.rbc-month-row + .rbc-month-row {
 /*border-color: rgba(255,255,255,0.1);*/
}

.rbc-timeslot-group {
  min-height: 100px;
}
.rbc-today{
  /* background-color: rgba(255, 253, 234, 0.1);*/
  background-color: rgb(243 200 109 / 20%);
}

.rbc-toolbar button {
  /*color: #ced9e0;*/
}
.rbc-off-range-bg {
  background: rgb(26 21 42 / 40%);
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border-color: rgba(255,255,255,0.5);
}
.rbc-time-view {
  background-color: #ffffff;
  border-radius: 16px;
}
.rbc-btn-group button {
  cursor: pointer;
}
.rbc-toolbar-label {
  font-size: 1.75rem;
  text-transform: uppercase;
  font-weight: 500;
}